body {
  //margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behaviour: smooth;
}

::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

#header-socials::after {
  content: "";
  width: 2px;
  height: 50px;
  text-align: center;
  background: black;
  margin: auto;
}
